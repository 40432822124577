import Head from 'next/head'
import { Box, Stack, GlobalStyles } from '@mui/material'
import { useEffect, forwardRef } from 'react'
import { ThemeColorCommonBackground } from 'settle-ui/tokens'
import type { IFooterProps } from 'routes/components/ExternalAppLayout/components/Footer/Footer'
import type { IHeaderProps } from 'routes/components/ExternalAppLayout/components/Header/Header'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'
import { useUrlBasedSnackbar } from 'routes/hooks/useUrlBasedSnackbar/useUrlBasedSnackbar'
import * as $ from './ExternalAppLayout.styled'

const ROOT_ID = 'external-app-layout'
const GLOBAL_STYLES = (
  <GlobalStyles
    styles={{
      'html, body': {
        position: 'fixed',
        overflow: 'hidden',
        background: ThemeColorCommonBackground,
      },
      [`html, body, #__next, #${ROOT_ID}`]: {
        height: '100%',
        width: '100%',
      },
    }}
  />
)

export type TExternalAppLayoutProps = Pick<IHeaderProps, 'loggedIn' | 'links'> &
  Pick<IFooterProps, 'supportContactInfo'> & {
    className?: string
    'data-test-id'?: string
  }

export const ExternalAppLayout = forwardRef<HTMLDivElement, React.PropsWithChildren<TExternalAppLayoutProps>>(
  ({ links, children, loggedIn, supportContactInfo, ...props }, ref) => {
    useUrlBasedSnackbar()

    const businessId = useBusinessIdPathParam()

    useEffect(() => {
      if (businessId) {
        window.localStorage.setItem('business_id', businessId)
      }
    }, [businessId])

    return (
      <>
        {GLOBAL_STYLES}
        <Head>
          <meta name="viewport" content="minimum-scale=1,initial-scale=1,width=device-width,shrink-to-fit=no" />
        </Head>
        <Box
          {...props}
          ref={ref}
          id={ROOT_ID}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <$.Header loggedIn={loggedIn} links={links} />
          <Stack sx={{ flex: 1 }}>{children}</Stack>
          <$.Footer supportContactInfo={supportContactInfo} />
        </Box>
      </>
    )
  },
)

ExternalAppLayout.displayName = 'ExternalAppLayout'
